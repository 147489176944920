import { render, staticRenderFns } from "./underlineSign.vue?vue&type=template&id=dd25b544&scoped=true"
import script from "./underlineSign.vue?vue&type=script&lang=js"
export * from "./underlineSign.vue?vue&type=script&lang=js"
import style0 from "./underlineSign.vue?vue&type=style&index=0&id=dd25b544&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd25b544",
  null
  
)

export default component.exports