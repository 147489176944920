<!--
 * @Description: 填写收货单
 * @Author: zhang zhen
 * @Date: 2023-05-30 11:26:43
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-17 19:50:59
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/uploadShippedFileImportant.vue
-->
<template>
  <DrawerView :visible="visible" :width="1200" title="填写收货单" @confirm="handleSubmit" @cancel="handleCancel">
    <a-tabs v-model="activePart" tab-position="left" class="step_form">
      <a-tab-pane v-for="(item, index) in transportsVo" :key="index">
        <template #tab>
          <img src="/to_input.png" alt="" class="to_input" v-if="!item.shipmentStatus || item.shipmentStatus == 1" />
          <img
            src="/sended.png"
            alt=""
            class="to_input"
            v-else-if="item.shipmentStatus && item.shipmentStatus == 2 && item.receiveStatus == 1"
          />
          <img src="/last_icon.png" alt="" class="to_input" v-else />
          <span class="label-span" style="margin-right: 15px">收货单{{ index + 1 }}</span>
        </template>
        <!-- 没发货信息 -->
        <div class="noPassInfo" v-if="!item.shipmentStatus || item.shipmentStatus == 1">
          <img src="/noTransport.png" alt="" class="icon" />
          <span class="memo">暂无发货信息</span>
        </div>
        <template v-else>
          <!-- 收货信息 -->
          <h2 class="page-title">
            <span class="title">收货数量</span>
          </h2>
          <a-table class="table-box" :columns="columns" :pagination="false" :data-source="item.shipmentItems" bordered>
            <template slot="receiveQuantity" slot-scope="text, record, index">
              <a-input-number
                id="inputNumber"
                v-model="record.receiveQuantity"
                :min="0"
                :max="record.shipmentQuantity"
                placeholder="请填写实际收货数量"
                :precision="0"
              />
            </template>
          </a-table>
          <h2 class="page-title">
            <span class="title">收货单据</span>
          </h2>
          <!-- 预览单据图片 -->
          <div class="order-pic" v-if="false">
            <img preview="1" src="/order_pic.png" alt="" class="cover" />
            <a>下载</a>
          </div>
          <a-form-model :colon="false">
            <a-form-model-item>
              <div class="uploadCover">
                <UploadDrag
                  v-model="item.uploadFiles"
                  @change="handleChangeFile($event, item)"
                  :number="1"
                  :orderNo="item.id"
                />
              </div>
            </a-form-model-item>
          </a-form-model>

          <a-form-model
            class="info-form"
            :label-col="{
              span: 6,
            }"
            :wrapper-col="{
              span: 18,
            }"
            :colon="false"
          >
            <h2 class="page-title">
              <span class="title">发货信息</span>
            </h2>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="发货方式" prop="shipmentType" required>
                  <j-dictSelect
                    v-model="item.shipmentType"
                    style="width: 100%"
                    placeholder="请选择"
                    dictCode="1018"
                    :disabled="true"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="运单号" prop="shipmentNo" v-if="item.shipmentType != '1018002'" required>
                  <a-input
                    placeholder="请输入"
                    v-model="item.shipmentNo"
                    style="width: 100%"
                    :disabled="true"
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="发货时间" prop="shipmentTime" required>
                  <JDate
                    placeholder="请选择"
                    style="width: 100%"
                    v-model="item.shipmentTime"
                    :removePast="true"
                    :disabled="true"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <h2 class="page-title">
              <span class="title">收货信息</span>
            </h2>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="期望收货时间">
                  <j-date placeholder="请选择" v-model="item.expectedDeliveryDate" style="width: 100%" disabled />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="收货人">
                  <a-input placeholder="请输入" v-model="item.receiveName" style="width: 100%" disabled></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="手机号">
                  <a-input placeholder="请输入" v-model="item.receivePhone" style="width: 100%" disabled></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="24">
                <a-form-model-item
                  label="收货地址"
                  :label-col="{
                    span: 3,
                  }"
                  :wrapper-col="{
                    span: 21,
                  }"
                >
                  <a-input
                    placeholder="请输入"
                    class="lastBtn"
                    v-model="item.receiveDetailAddress"
                    style="width: 100%"
                    :maxLength="20"
                    disabled
                  >
                    <div class="NumberInfo" slot="suffix">
                      {{ (item.receiveDetailAddress && item.receiveDetailAddress.length) || 0 }}/20
                    </div>
                  </a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </template>
      </a-tab-pane>
    </a-tabs>

    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65)">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">确认</a-button>
    </template>
  </DrawerView>
</template>

<script>
import UploadDrag from './uploadTemplate.vue'
import { postAction, getAction } from '@/api/manage'
import JDate from '@/components/easyComponents/JDate.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import DrawerView from '@/components/plugins/drawerView.vue'
import dayjs from 'dayjs'

export default {
  name: 'uploadShippedFileImportant',
  components: {
    UploadDrag,
    DrawerView,
    JDate,
    JDictSelect,
  },
  inject: ['handleReloadData'],
  data() {
    return {
      activePart: 0,
      visible: false,
      loading: false,
      uploadFiles: [],
      orderNo: null,
      transportsVo: [],
      columns: [
        {
          title: '散件SKU名称',
          dataIndex: 'skuName',
          width: 163,
        },
        {
          title: '包装材料',
          dataIndex: 'wrapTypeDictName',
          width: 173,
        },
        {
          title: '计划所需数量(个)',
          dataIndex: 'skuPlanQuantity',
          width: 183,
        },
        {
          title: '实际发货数量(个)',
          dataIndex: 'shipmentQuantity',
          width: 183,
        },
        {
          title: '实际收货数量(个)',
          dataIndex: 'receiveQuantity',
          width: 183,
          scopedSlots: { customRender: 'receiveQuantity' },
        },
      ],
      dataSource: [],
      receiveRealName: null,
    }
  },
  methods: {
    handleOpen({ orderNo, purchaseBusinessName }) {
      this.visible = true
      this.orderNo = orderNo
      this.receiveRealName = purchaseBusinessName
      this.handleLoadOrder()
    },
    handleLoadOrder() {
      getAction('/order/query/delivery', {
        orderNo: this.orderNo,
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          this.transportsVo = data && data.map(i => ({
            ...i,
            shipmentTime: i.shipmentTime && dayjs(new Date(i.shipmentTime)).format('YYYY-MM-DD') || null
          })) || []
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleChangeFile(e, item) {
      item.uploadFiles = e.splice(-1, 1)
      item.receiveFile = item.uploadFiles[0]['url']
    },
    handleSubmit() {
      //  if (!this.uploadFiles.length) return this.$message.warning('请上传收货单据文件')
      const { receiveFile, shipmentItems, orderNo, id, shipmentStatus, receiveStatus } =
        this.transportsVo[this.activePart]
      if (!shipmentStatus) return this.$message.warning('当前收货单尚未发货')
      if (receiveStatus && receiveStatus == 2) return this.$message.warning('当前收货单已收货')
      if (!receiveFile) return this.$message.warning('请上传收货单据文件')
      let shipList = []
      for (let i = 0; i < shipmentItems.length; i++) {
        if (!shipmentItems[i].receiveQuantity) return this.$message.warning('请填写收货数量')
        const { id, receiveQuantity, shipmentQuantity } = shipmentItems[i]
        shipList.push({
          id,
          receiveQuantity,
          shipmentQuantity,
        })
      }
      let formData = {
        receiveFile,
        orderNo,
        id,
        receiveRealName: this.receiveRealName,
        shipmentItems: shipList,
        realReceiveDate: dayjs(new Date()).format('YYYY-MM-DD') 
      }
      this.confirmLoading = true
      postAction('/order/receive', formData)
        .then((res) => {
          const { success, message } = res
          this.confirmLoading = false
          if (success) {
            this.handleReloadData(1) // 刷新主列表
            this.transportsVo[this.activePart]['receiveStatus'] = 2
            let flag = this.transportsVo.every((i) => i.receiveStatus == 2)
            if (flag) {
              this.handleCancel()
            }
          } else {
            this.$message.warning(message)
          }
        })
        .catch((e) => {
          this.confirmLoading = false
        })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.uploadFiles = []
      this.activePart = 0
    },
  },
}
</script>

<style lang="less" scoped>
.uploadCover {
  width: 740px;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #efefef;
  .endTitle {
    width: 424px;
    margin-top: -15px;
    text-align: center;
    line-height: 20px;
    // .flexLayout();
    font-size: 12px;
    // justify-content: center;
    color: #8c8c8c;
    margin-top: 8px;

    span.text {
      color: #ff6026;
    }
  }
}

.ant-form-item-label > label {
  color: #000;
}

.part-title {
  margin-top: 24px;
  //styleName: 14/加粗;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  color: #000;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding-bottom: 8px;
  margin-bottom: 25px;

  &::before {
    display: block;
    content: '';
    width: 5px;
    height: 16px;
    background: #ff6026;
    margin-right: 8px;
  }
}

.table-box {
  min-height: auto !important;

  ::v-deep .ant-table-tbody tr:not(:last-of-type) > td {
    border-right: 1px solid #e8e9eb !important;
  }

  ::v-deep .ant-table-thead > tr > th {
    background: #f4f5f7;
    border-right: 1px solid #e8e8e8 !important;
    border-bottom: 1px solid #e8e8e8 !important;

    padding: 8px 10px;
    font-size: 14px;
  }

  ::v-deep .ant-table-column-title {
    font-size: 14px;
  }

  ::v-deep .ant-table-tbody tr > td {
    border-bottom: 1px solid #e8e9eb !important;
    padding: 5px 10px;
  }

  ::v-deep .ant-table-wrapper {
    min-height: auto !important;
    border-bottom: none;
  }

  ::v-deep .ant-input-number {
    width: 100%;
    height: 28px;
    line-height: 28px;
    .ant-input-number-input {
      height: 28px;
      font-size: 14px;
      font-weight: 350;
      &::placeholder {
        color: #8c8c8c;
        /* 设置占位符文本的颜色 */
      }
    }
  }

  // ::v-deep .ant-input {
  //   border: none;
  //   outline: 0;

  //   &:focus {
  //     border: none;
  //     box-shadow: none;
  //   }
  // }

  ::v-deep .ant-input-number-handler-wrap {
    display: none;
  }
}

.step_form {
  ::v-deep .ant-tabs-content {
    padding-left: 25px;
  }
}

// ::v-deep .ant-tabs-ink-bar-animated {
//   height: 24px!important;
// }
::v-deep .ant-tabs-nav .ant-tabs-tab {
  padding: 1px 0;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

::v-deep .ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
}

img.to_input {
  width: 52px;
  margin-right: 15px;
}

span.label-span {
  display: inline-block;
  width: 90px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.page-title {
  &:not(:first-of-type) {
    margin-top: 28px;
  }
  &:first-of-type {
    margin-top: -8px;
  }

  font-size: 15px;
  color: #605f5f;
  margin-top: 8px;
  margin-bottom: 24px;
  height: 38px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  position: relative;

  &:before {
    background: #ff6e2d;
    display: inline-block;
    content: '';
    width: 5px;
    height: 16px;
    margin-right: 8px;
  }

  .title {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-right: 10px;
  }

  .action {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    color: #ff6026;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;

    img.icon {
      width: 16px;
      margin-right: 4px;
    }
  }
}

.info-form {
  ::v-deep .ant-select-selection {
    height: 38px;
  }
  ::v-deep .ant-select-selection__rendered {
    line-height: 38px;
  }

  ::v-deep .ant-calendar-picker-input.ant-input {
    height: 38px;
    line-height: 38px;
  }

  ::v-deep .ant-select-selection--multiple {
    padding-bottom: 0;
  }

  ::v-deep .ant-select-selection--multiple .ant-select-arrow {
    top: 18px;
  }

  ::v-deep .ant-select-arrow {
    margin-top: -8px;
  }

  ::v-deep .ant-input {
    height: 38px;
    line-height: 38px;

    // color: #131212;
    &::placeholder {
      color: #8c8c8c;
      /* 设置占位符文本的颜色 */
    }
  }
  ::v-deep .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }
  ::v-deep .ant-select-selection__placeholder {
    color: #8c8c8c;
  }
}
.noPassInfo {
  margin: 134px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  img.icon {
    width: 160px;
    margin-bottom: 15px;
    .memo {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
      color: #131212;
    }
  }
}

.order-pic {
  width: 300px;
  display: flex;
  align-items: flex-end;
  img.cover {
    width: 240px;
    height: 164px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }
}
</style>
