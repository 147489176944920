<!--
 * @Description: 协议签署 - 线下
 * @Author: zhang zhen
 * @Date: 2024-09-05 16:20:46
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-05 19:39:52
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/underlineSign.vue
-->

<template>
  <DrawerView :visible="visible" :width="800" :showSlotTitle="true" @confirm="handleSubmit" @cancel="handleCancel">
    <template #title>
      <div class="action-header">
        <span class="title">协议签署</span>
      </div>
    </template>
    <!-- <a-alert show-icon>
      <img src="/warning_icon.png" alt="" slot="icon" class="info-icon" />
      <div class="alert-area" slot="message">
        <span class="label">为保障合同具有合法效力，双方签署的合同必须同时在线签署，或同时线下签署。</span>
      </div>
    </a-alert> -->

    <!-- 用户显示区域 -->
    <div class="user-area">
      <div class="user-area-item">
        <img src="~@/assets/order/userIcon_1.png" alt="" class="avatar" />
        <div class="info-box">
          <span class="name">采购商：{{ basicInfo.purchaseBusinessName }}</span>
          <div class="signType">
            <span class="type">签署方式：线下签署</span>
          </div>
        </div>
      </div>
      <div class="user-area-item">
        <img src="~@/assets/order/userIcon_2.png" alt="" class="avatar" />
        <div class="info-box">
          <span class="name">供应商：{{ basicInfo.supplierBusinessName }}</span>
          <div class="signType">
            <span class="type">签署方式：线下签署</span>
          </div>
        </div>
      </div>
    </div>
    <h2 class="RequirementConfirmation-title"><span class="title">线下签署</span></h2>
      <div class="uploadCover">
        <contractUploader
          ref="UploadDragRef"
          :orderNo="basicInfo.orderNo"
          v-model="uploadFiles"
          :isUpload="isUpload"
          @change="handleChangeFile"
          :number="1"
        />
      </div>
    <a-modal title="温馨提示" :width="580" :visible="showDig1" centered @ok="handleOKEnter" @cancel="showDig2">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>您已线下签署合同，如再线上签署将废弃之前合同，确定重新签署吗？</span>
      </div>
    </a-modal>
    <a-modal
      title="温馨提示"
      :width="570"
      :visible="showDig2"
      centered
      @ok="handlePassChange"
      @cancel="showDig2 = false"
    >
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span
          >对方已在线签署/线下签署合同，为保障合同具有法律效力，请选择<b>同一签署方式</b>
          。如确定选择在线签署/线下签署，请及时联系对方更换签署方式。</span
        >
      </div>
    </a-modal>
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65)">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">确认</a-button>
    </template>
  </DrawerView>
</template>

<script>
import contractUploader from './contractUploader.vue'
import { postAction, getAction } from '@/api/manage'
import DrawerView from '@/components/plugins/drawerView.vue'
import emptyBox from './emptyBox.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'underlineSign',
  components: {
    contractUploader,
    DrawerView,
    emptyBox,
  },
  inject: ['handleReloadData'],
  props: {
    orderNo: {
      type: String,
      default: '',
    },
    tradeIdentity: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isSign: false,
      signFileUrl: '',
      SignRange: [],
      labelCol: { span: 7 },
      wrapperCol: { span: 14 },
      showDig1: false,
      showDig2: false,
      changeTab: '1',
      visible: false,
      loading: false,
      isUpload: false,
      uploadFiles: [],
      basicInfo: {},
      activeKey: '1',
      codeUrl: '异常',
      contractInfo: {},
      looperTimer: null,
      hasFile: false,
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleOpen(item) {
      this.visible = true
      const { bizOrderNo, purchaseBusinessName, supplierBusinessName, contractNo } = item
      this.basicInfo = {
        purchaseBusinessName,
        supplierBusinessName,
        orderNo: bizOrderNo,
        contractNo,
      }
    },
    handleOKEnter() {
      this.showDig1 = false;
      this.handleJump()
    },
    handlePassChange() {
      this.showDig2 = false
      this.handleJump()
    },
    handleChangeSignType() {
      postAction('/v1/contract/order/initiate', {
        signType: Number(this.activeKey),
        orderNo: this.basicInfo.orderNo,
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          if (this.activeKey == '1') {
            const { purchaseSignFile, supplierSignFile, status } = data
            this.codeUrl = this.setUserInfo().tradeIdentity == 0 ? supplierSignFile : purchaseSignFile
          }
          this.contractInfo = data
        } else {
          this.$message.error(message)
        }
      })
    },
    handleOpenSign() {
      // 当前tab打开
      window.open(this.codeUrl)
    },
    handleViewFile(file) {
      this.$nextTick((_) => {
        this.$refs.FileListViewRef.handleGetFileList([file])
      })
    },
    handleSetFileUrl(url) {
      this.$nextTick((_) => {
        this.$refs.UploadDragRef.initFileList([
          {
            url,
            srcFileName: url.split('/images/')['1'],
          },
        ])
      })
    },
    handleChangeFile(e) {
      this.uploadFiles = e.splice(-1, 1)
    },
    handleSubmit() {
      if (!this.uploadFiles.length)
        return this.$message.warning({
          content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">请上传合同文件</span>,
          icon: (h) => <img src="./noPass.png" width="21" height="21" />,
        })
      this.loading = true
      postAction('/v1/contract/order/offline/sign', {
        contractFile: this.uploadFiles[0]['url'],
        orderNo: this.basicInfo.orderNo,
        contractNo: this.basicInfo.contractNo
      }).then((res) => {
        const { success, message } = res
        this.loading = false
        if (success) {
          this.handleCancel()
          this.handleReloadData(1)
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.uploadFiles = []
    },
    handleChangeType() {
      if (this.hasFile) {
        return this.showDig1 = true;
      }
      if (this.contractInfo && this.contractInfo.signType != Number(e)) {
        return (this.showDig2 = true)
      }
      this.handleJump()
    },
    handleJump() {
      this.handleCancel()
      this.$emit('changeType', this.basicInfo)
    },
  },
}
</script>

<style lang="less" scoped>
.RequirementConfirmation {
  &-title {
    font-size: 15px;
    color: #605f5f;
    margin-bottom: 24px;
    height: 38px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;

    &:before {
      background: #ff6e2d;
      display: inline-block;
      content: '';
      width: 5px;
      height: 16px;
      margin-right: 8px;
    }

    .title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000;
      margin-right: 10px;
    }
  }
}
::v-deep .ant-tabs-tab {
  padding: 12px 0 20px;
  font-size: 16px;
}

::v-deep .ant-alert-info {
  border: none;
  background: rgba(255, 125, 47, 0.08);
  border-radius: 4px;
  height: 40px;

  &.success-alert {
    background: #e6faf3;
  }

  &.error-alert {
    background: #ee42611a;
  }

  .info-icon {
    width: 17px;
    margin-top: -9px;
  }

  .ant-alert-message {
    color: #131212;
  }
}

.uploadCover {
  margin-top: 25px;
  width: 752px;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .endTitle {
    margin-top: -15px;
    .flexLayout();
    font-size: 12px;
    justify-content: center;
    color: #8d8e99;
    margin-top: 8px;
  }
}

.ant-form-item-label > label {
  color: #000;
}

.newForm {
  ::v-deep .ant-form-item-label {
    width: auto !important;
  }
}

.user-area {
  display: flex;
  align-items: center;
  grid-gap: 0 15px;
  margin-top: 23px;
  margin-bottom: 15px;
  &-item {
    width: 364px;
    height: 84px;
    background: #f4f5f7;
    border: 1px solid #e8e9eb;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    box-sizing: border-box;
    .avatar {
      width: 52px;
      margin-right: 8px;
    }
    .info-box {
      flex: 1 0 0;
      min-width: 0;
      height: 52px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        color: #131212;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
      }
      .signType {
        width: 100%;
        display: flex;
        align-items: center;
        //styleName: 常规/正文1 body_md_regular;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #5e5e66;
      }
    }
    img.tag {
      width: 52px;
    }
  }
}
::v-deep .ant-tabs-top-bar {
  margin-bottom: 0;
}
.online-sign {
  display: flex;
  align-items: center;
  height: calc(100vh - 381px);
  // min-height: 316px;
  background: #f7f8fa;
  grid-gap: 0 8px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 32px;
  }
  .card_1 {
    width: 336px;
    height: 100%;
    background: #fff;
  }
  .card_2 {
    flex: 1 0 0;
    min-width: 0;
    height: 100%;
    background: #fff;
  }
  .title {
    color: #222226;
    font-family: PingFang SC;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    margin-bottom: 4px;
  }
  .sub-title {
    color: #999;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    margin-bottom: 16px;
  }
  .action-btn {
    width: 132px;
    height: 38px;
  }
  .code {
    width: 160px;
    height: 160px;
  }
}

.info-card {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
  img {
    width: 21px;
    height: 21px;
    margin-top: 3px;
  }

  span {
    margin-left: 10px;
    font-weight: 380;
    color: #000000d9;
  }
}

.action-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-right: 52px;
}
.linkBtn {
  background: #f9a519;
  border-color: #f9a519;
}
</style>
