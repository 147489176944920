<!--
 * @Description: 上传付款单据
 * @Author: zhang zhen
 * @Date: 2023-05-30 09:57:57
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-07-15 17:23:26
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/uploadPaymentInfo.vue
-->
<template>
  <DrawerView :visible="visible" :width="800" title="上传付款单" @confirm="handleSubmit" @cancel="handleCancel">
    <a-form-model
      ref="infoForm"
      :model="form"
      :label-col="{
        span: 3
      }"
      :wrapper-col="{
        span: 21
      }"
      :rules="rules"
      :colon="false"
    >
      <a-form-model-item label="付款方式" prop="payMode">
        <j-dictSelect v-model="form.payMode" style="width: 320px" placeholder="请选择付款方式" dictCode="1044" @change="handleChangePayMode" disabled />
      </a-form-model-item>
      <a-form-model-item label="预付金额" prop="paid" v-if="form.payMode == '1044001' || form.payMode == '1044002'">
        <a-input-number :min="0" v-model="form.paid" style="width: 320px" placeholder="请输入预付金额" />
      </a-form-model-item>
      <a-form-model-item label="发票类型" prop="fapiaoType" v-if="false">
        <a-select v-model="form.fapiaoType" style="width: 320px" placeholder="请选择发票类型">
          <a-icon type="caret-down" slot="suffixIcon" style="color: #8C8C8C" />
          <a-select-option :value="0">
            无发票
          </a-select-option>
          <a-select-option :value="1">
            收据
          </a-select-option>
          <a-select-option :value="2">
            电子发票
          </a-select-option>
          <a-select-option :value="3">
            服务发票
          </a-select-option>
          <a-select-option :value="4">
            增值税专用发票
          </a-select-option>
          <a-select-option :value="5">
            增值税普通发票
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="备注">
        <a-textarea
          placeholder="请输入备注"
          allow-clear
          v-model="form.description"
          style="width: 623px;"
          :autosize="{ minRows: 6, maxRows: 6 }"
        />
      </a-form-model-item> -->
      <a-form-model-item label="单据上传" required>
        <div class="uploadCover">
          <div style="width: 334px">
            <UploadDrag v-model="uploadFiles" @change="handleChangeFile" :number="1" />
          </div>
          <div class="endTitle">可支持多种文件格式，如PDF，XLS、PNG、JPG等</div>
        </div>
      </a-form-model-item>
    </a-form-model>
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65);">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">确认</a-button>
    </template>
  </DrawerView>
</template>

<script>
import UploadDrag from '@/components/plugins/uploadDrag'
import DrawerView from '@/components/plugins/drawerView.vue'
import { postAction, postQueryAction } from '@/api/manage'
import JDictSelect from '@/components/plugins/JDictSelect.vue'

export default {
  name: 'uploadPaymentInfo',
  components: {
    UploadDrag,
    DrawerView,
    JDictSelect
  },
  props: {
    orderNo: {
      type: String,
      default: ''
    }
  },
  inject: ['handleReloadData'],
  data() {
    return {
      visible: false,
      loading: false,
      uploadFiles: [],
      form: {
      },
      rules: {
        fapiaoType: { required: true, message: '请选择发票类型' },
        payMode: { required: true, message: '请选择付款方式' },
        paid: { required: true, message: '请输入预付金额' },
      }
    }
  },
  // payFile
  methods: {
    handleChangePayMode(e) {
      this.form.paid && delete this.form.paid
    },
    handleShowModal(orderNo) {
      // 回显示信息
      postQueryAction('/order/PaymentQuery', { orderNo }).then(res => {
        const { success, data } = res;
        if (success) {
          const { paymentMethod } = data;
          this.$set(this.form, 'payMode', paymentMethod)
        }
      })
      this.visible = true
    },
    handleChangeFile(e) {
      this.uploadFiles = e.splice(-1, 1)
    },
    handleSubmit() {
      // 付款方式不是预付或预付定金的，不强制上传付款凭证
      if (this.form.payMode && (this.form.payMode == '1044001' || this.form.payMode == '1044002')) {
        if (!this.uploadFiles.length) return this.$message.warning('请上传预付款单')
      }
      this.$refs['infoForm'].validate(valid => {
        if (valid) {
          this.loading = true
          postAction('/order/pre/payment', {
            payAmount: this.form.paid,
            orderNo: this.orderNo,
            paymentVoucher: this.uploadFiles && this.uploadFiles.length ? this.uploadFiles[0]['url'] : null
          }).then(res => {
            const { success, message } = res
            this.loading = false
            if (success) {
              // this.$message.success(message)
              this.handleCancel()
              this.handleReloadData(1)
            } else {
              this.$message.warning(message)
            }
          }).catch(err => {
            this.loading = false
          })
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.uploadFiles = []
      this.form = {
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/form.less';

.uploadCover {
  width: 560px;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .endTitle {
    margin-top: -15px;
    .flexLayout();
    font-size: 12px;
    justify-content: center;
    color: #8d8e99;
    margin-top: 8px;
  }
}
.ant-form-item-label > label {
  color: #000;
}
</style>
