<!--
 * @Description: 收货单上传
 * @Author: zhang zhen
 * @Date: 2023-08-16 21:56:03
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-17 20:27:16
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/uploadTemplate.vue
-->
<template>
  <div class="uploadView">
    <div class="uploadView-title" style="padding-bottom: 20px;padding-top: 26px;">
      请按照要求填写完整，并上传文件
      <a @click="handleGetTemplate" v-show="needDowLoad">
        <div style="display: flex;font-size: 14px; justify-content: center;align-items: center;margin-top: 15px;font-weight: 400;">
          <img src="/download_icon.png" alt class="arrow" slot="suffixIcon" width="20px" />
          <span>下载收货单据</span>
        </div>
      </a>
    </div>

    <a-upload name="file" :multiple="false" :action="uploadAction" :disabled="disabled" :headers="headers" :fileList="fileList"
      @change="handleChangeImage" :before-upload="beforeUpload">
      <a-button type="primary" style="width: 248px;height: 40px;font-size: 16px;">
       <img src="/upload_top.png" alt="" class="icon">上传收货单据
      </a-button>
    </a-upload>
    <p style="color:#8C8C8C;margin-top: 14px;margin-bottom: 32px;font-size: 14px;line-height: 20px;text-align: center;">单据下载完成后，请打印并填写单据上的 <span style="color: #FF6026;">收货人签字、</span><span style="color: #FF6026;">收货日期</span> , 单据填写完成后，请<span style="color: #FF6026;">电子扫描</span> 并上传已填写的发货单据</p>
  </div>
</template>

<script>
import { downGetFiles } from '@/api/manage'
export default {
  name: 'excelUploadFile',
  props: {
    needDowLoad: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    orderNo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uploadAction: `${process.env.VUE_APP_API_BASE_URL}/file/images/upload`,
      headers: {},
      fileList: [],
      templateParams: null,
    }
  },
  created() {
    this.headers = { token: JSON.parse(localStorage.getItem('authInfo'))['Authorization'] }
  },
  methods: {
    handleInit(item) {
      this.templateParams = item;
    },
    handleGetTemplate(item) {
      downGetFiles('/order/delivery/download', {
        id: this.orderNo,
      }).then((res) => {
        let url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', window.TemplateName);
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) // 下载完成移除元素
        window.URL.revokeObjectURL(url) // 释放掉blob对象
      })
    },
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const isLimit = file.size / 1024 / 1024 <= 10;
        if (!isLimit) {
          this.$message.error("上传文件大小不能超过 10MB!");
          return reject(false);
        }
        return resolve(true);
      });
    },
    handleChangeImage(info) {
      const { file } = info
      if (file) {
        const { status, response, uid } = file
        if (status == 'done') {
          if (!response.success) {
            info.fileList = info.fileList.filter((i) => i.uid != uid) // 过滤上传失败的文件
          }
        }
      }
      if (!info.file.status && this.uploadGoOn === false) {
        info.fileList.pop()
      }
      let fileList = info.fileList
      if (info.file.status === 'done') {
        if (this.number > 0) {
          fileList = fileList.slice(-this.number)
        }
        if (info.file.response.success) {
          fileList = fileList.map((res) => {
            if (res.response && res.response.data) {
              const { url, filename, ext } = res.response.data
              res.url = url
              res.srcFileName = filename
              res.fileName = filename
              res.fileType = ext
            }
            return res
          })
          // console.log(fileList)
          // this.$message.success(`${info.file.name} 上传成功!`)
        } else {
          this.$message.error(
            `${info.file.response.success ? info.file.response.message : '文件上传失败，不支持当前格式'}!`
          )
        }
      } else if (info.file.status === 'error') {
        this.$message.warning({
          content: (h) => (
            <span style="margin-left: 9px; font-weight: 500;color: #262626;">{`${info.file.name} 上传失败.`}</span>
          ),
          icon: (h) => <img src="./noPass.png" width="21" height="21" />,
        })
      } else if (info.file.status === 'removed') {
        // this.handleDelete(info.file)
      }
      this.fileList = fileList.filter((i) => i.status != 'error')
      if (info.file.status === 'done' || info.file.status === 'removed') {
        let newFileList = []
        for (let a = 0; a < fileList.length; a++) {
          if (fileList[a].status === 'done') {
            console.log(fileList[a])
            let fileObj = {
              srcFileName: fileList[a].srcFileName,
              url: fileList[a].url,
            }
            newFileList.push(fileObj)
          } else {
            return
          }
        }
        this.$emit('change', newFileList)
      }
    },
  }
}
</script>

<style lang="less" scoped>
.uploadView {
  width: 692px;
  background-color: #f9f9f9;
  border-radius: 8px;
  min-height: 245px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  &-title {
    width: 100%;
    padding-bottom: 40px;
    color: #262626;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    border-bottom: 1px solid #efefef;
    margin-bottom: 30px;
  }
}

.icon {
  width: 20px;
  margin-right: 4px;
  margin-bottom: 4px;
}
</style>
