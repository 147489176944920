<!--
 * @Description: 已收货 - 上传付款单
 * @Author: zhang zhen
 * @Date: 2023-05-30 16:12:57
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-17 21:31:22
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/UploadPayedFile.vue
-->
<template>
  <DrawerView :visible="visible" :width="800" title="上传付款单" @confirm="handleSubmit" @cancel="handleCancel">
    <a-form-model :colon="false">
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="应付金额" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" label-align="left"
            ><a-tag color="#2db7f5">{{ payInfo.orderAmount || '0' }}</a-tag> 元</a-form-model-item
          >
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="已付金额" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" label-align="left"
            ><a-tag color="#87d068">{{ payInfo.payAmount || '0' }}</a-tag> 元</a-form-model-item
          >
        </a-col>
      </a-row>
      <a-form-model-item
        label="付款金额"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 12 }"
        label-align="left"
        required
      >
        <a-input v-model="payAmount" placeholder="请输入付款金额" @input="handleInput"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="上传单据"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 21 }"
        label-align="left"
        :required="payInfo.payAmount < payInfo.orderAmount"
      >
        <div class="uploadCover">
          <div style="width: 334px">
            <UploadDrag v-model="uploadFiles" @change="handleChangeFile" :number="1" />
          </div>
          <div class="endTitle">可支持多种文件格式，如PDF，XLS、PNG、JPG等</div>
        </div>
      </a-form-model-item>
    </a-form-model>
    <template v-slot:btnArea>
      <a-button @click="handleCancel" style="color: rgba(0, 0, 0, 0.65)">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">确认</a-button>
    </template>
  </DrawerView>
</template>

<script>
import UploadDrag from '@/components/plugins/uploadDrag'
import { postAction, getAction } from '@/api/manage'
import DrawerView from '@/components/plugins/drawerView.vue'
export default {
  name: 'UploadPayedFile',
  components: {
    UploadDrag,
    DrawerView,
  },
  inject: ['handleReloadData'],
  props: {
    orderNo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      uploadFiles: [],
      payInfo: {},
      newOrderNo: null,
      payAmount: null,
    }
  },
  methods: {
    handleInput(event) {
      let value = event.target.value
      // 移除非数字字符
      value = value.replace(/[^0-9.]/g, '')
      // 防止出现多个小数点
      value = value.replace(/\.{2,}/g, '.')
      // 如果有小数点，则确保小数部分不超过4位
      if (value.includes('.')) {
        const parts = value.split('.')
        if (parts[1].length > 4) {
          value = `${parts[0]}.${parts[1].substring(0, 4)}`
        }
      }
      // 确保总长度不超过10位
      if (value.length > 10) {
        value = value.substring(0, 10)
      }
      this.payAmount = value
    },
    handleOpen(orderNo) {
      this.visible = true
      this.newOrderNo = orderNo
      getAction('/order/queryByOrderNo', {
        orderNo: orderNo || this.newOrderNo,
      }).then((res) => {
        const { success, message, data } = res
        if (success) {
          const { payAmount, orderAmount } = data
          this.payInfo = {
            payAmount,
            orderAmount,
          }
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleChangeFile(e) {
      this.uploadFiles = e.splice(-1, 1)
    },
    handleSubmit() {
      if (!this.payAmount) return this.$message.warning('请输入付款金额')
      if (this.payInfo.payAmount < this.payInfo.orderAmount && !this.uploadFiles.length) return this.$message.warning('请上传付款单')
      this.loading = true
    return;
      postAction('/order/payment', {
        paymentVoucher: this.uploadFiles[0]['url'],
        orderNo: this.orderNo || this.newOrderNo,
        payAmount: this.payAmount,
      }).then((res) => {
        const { success, message } = res
        this.loading = false
        if (success) {
          // this.$message.success(message)
          this.handleCancel()
          this.handleReloadData(1) // 刷新主列表
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.uploadFiles = []
      this.payInfo = {}
      this.payAmount = null
    },
  },
}
</script>

<style lang="less" scoped>
.uploadCover {
  width: 560px;
  min-height: 244px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .endTitle {
    margin-top: -15px;
    .flexLayout();
    font-size: 12px;
    justify-content: center;
    color: #8d8e99;
    margin-top: 8px;
  }
}
.ant-form-item-label > label {
  color: #000 !important;
}
</style>
